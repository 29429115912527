import React from 'react';
import {Select, Row, Col} from 'antd';

import './styles.scss';

const Pagination = props => {
  const {onPageChange, totalCount, currentPage, pageSize, className} = props;

  const range = (start, end) => {
    const length = end - start + 1;
    /*
            Create an array of certain length and set the elements within it from
          start value to end value.
        */
    return Array.from({length}, (_, idx) => idx + start);
  };
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const listPage = range(1, totalPageCount);

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = listPage.length;

  return (
    <Row className={`pagination-container ${className}`}>
      <Col span={6}>
        {currentPage !== 1 && (
          <Col className='pagination-item previous' onClick={onPrevious}>
            Trước
          </Col>
        )}
      </Col>
      <Col span={12} className='center-content'>
        <div className='center-label'>Trang</div>
        <Select filterOption={false} value={currentPage} onChange={value => onPageChange(value)}>
          {listPage.map(x => (
            <Select.Option key={x} value={x}>
              {x}
            </Select.Option>
          ))}
        </Select>
        <div className='center-label'>trên {totalPageCount}</div>
      </Col>
      <Col span={6}>
        {currentPage < lastPage && (
          <Col className='pagination-item next' onClick={onNext}>
            Tiếp theo
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default Pagination;
