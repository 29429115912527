import React from 'react';
import {Alert, Row} from 'antd';
import {navigate} from 'gatsby';
import {filterTypes} from '~constants/product-list';
import ProductItem from '~components/product-item';
import Loading from '~components/page-loader';
import './styles.scss';
import DateRangePicker from '~components/date-range-picker';
import {formatDate} from '~utils/helper';
import Pagination from '~components/pagination';

const PAGE_SIZE = 30;

const Products = ({
  searchParams,
  products,
  total,
  pagination,
  currentPath,
  onShowMore,
  pagingLoading,
  updateCategoryState,
}) => {
  const onFilterChange = key => {
    searchParams.set('sort', key);
    navigate(`${currentPath}?${searchParams.toString()}`);
  };
  const handleShowMore = pageNumber => {
    if (onShowMore) onShowMore(pageNumber);
  };

  const onDateChange = dates => {
    if (dates.length === 0) {
      searchParams.delete('availableFrom');
      searchParams.delete('availableTo');
      navigate(`${currentPath}?${searchParams.toString()}`);
      return;
    }

    const dateFrom = formatDate(dates[0], 'YYYY-MM-DD HH:mm:ss');
    let dateTo = '';
    if (dates.length === 1) {
      dates[0].setHours(23, 59, 59, 999);
      dateTo = formatDate(dates[0], 'YYYY-MM-DD HH:mm:ss');
    }

    if (dates.length === 2) {
      dates[1].setHours(23, 59, 59, 999);
      dateTo = formatDate(dates[1], 'YYYY-MM-DD HH:mm:ss');
    }

    searchParams.set('availableFrom', dateFrom);
    searchParams.set('availableTo', dateTo);

    navigate(`${currentPath}?${searchParams.toString()}`);
  };

  const availableDates = () => {
    if (searchParams.get('availableFrom') && searchParams.get('availableTo')) {
      const dateFrom = new Date(searchParams.get('availableFrom'));
      dateFrom.setHours(0, 0, 0, 0);
      const dateTo = new Date(searchParams.get('availableTo'));
      dateTo.setHours(23, 59, 59, 999);
      return [dateFrom, dateTo];
    }
    return [];
  };

  if (products.length === 0)
    return <Alert description='Không tìm thấy sản phẩm phù hợp với lựa chọn của bạn' type='warning' />;

  return (
    <div>
      <Loading loading={pagingLoading} />
      <div className='header-product'>
        <div className='feature-date'>
          <div>
            <DateRangePicker dateRange={availableDates()} onChange={dates => onDateChange(dates)} />
          </div>
        </div>
        <div className='feature-sort'>
          {filterTypes.map(x => (
            <div
              className={`${x.key === (searchParams.get('sort') || filterTypes[0].key) && 'active'} sort-item`}
              onClick={() => onFilterChange(x.key)}
            >
              {x.title}
            </div>
          ))}
        </div>
      </div>
      {products.length > 0 && (
        <>
          <Row>
            {products.map(x => (
              <ProductItem key={x.id} item={x} updateCategoryState={updateCategoryState} />
            ))}
          </Row>
          {total > PAGE_SIZE && (
            <Pagination
              className='pagination-bar'
              currentPage={pagination.page}
              totalCount={total}
              pageSize={PAGE_SIZE}
              onPageChange={page => handleShowMore(page)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Products;
