import React from 'react';

const CollapseIcon = ({fill = '#828282', size = '7'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 8 6'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M4.27494 0.5L7.88338 5.5L0.666504 5.5L4.27494 0.5Z' />
  </svg>
);

export default CollapseIcon;
