import React from 'react';

const ChevronLeft = ({fill = '#D8D6D6', size = '12'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 10 16'
    width={size}
    height={size}
    fill={fill}
  >
    <path
      d='M9.24257 2.34317L7.82836 0.928955L0.757324 8.00001L7.82839 15.0711L9.24261 13.6569L3.58574 8L9.24257 2.34317Z'
      fill={fill}
    />
  </svg>
);

export default ChevronLeft;
