import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {get, post} from '~utils/api';
import Products from '~components/products';
import {BannerTypes, SortTabTypes} from '~utils/enum';
import {defaultPagination, filterPriceTypes} from '~constants/product-list';
import CategoryMenu from '~components/category-menu';
import Seo from '~components/seo';
import BigBanner from '~components/big-banner';
import * as bannerAction from '~actions/banner';
import {keepPaginationState} from '~actions/product';
import {resetCategoryState, setCategoryState} from '~actions/category';

import './styles.scss';

const Category = props => {
  const {
    category,
    searchParams,
    location,
    banners,
    resetCurCategoryState,
    getBanners,
    pagination,
    categoryState,
    setCurCategoryState,
  } = props;
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categoryMenu, setCategoryMenu] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [total, setTotal] = useState(0);
  const [productLoading, setProductLoading] = useState(false);
  const [pagingLoading, setPagingLoading] = useState(false);

  const updateCategoryState = () => {
    setCurCategoryState({
      products: [...products],
      pagination: {...pagination},
      total,
      categoryMenu,
      pathname: location.pathname,
      search: location.search,
      position: window.pageYOffset + window.screen.height,
    });

    document.getElementsByTagName('body')[0].style.height = '100vh';
  };

  const handleShowMore = nextPage => {
    if (products.length < total) {
      props.keepPagination({...pagination, page: nextPage});
      window.scrollTo(0, categoryState.position + 500);
    }
  };

  const getRequestParams = () => {
    let sort = {propName: 'UpdatedDate', direction: 'Desc'};
    switch (searchParams.get('sort')) {
      case SortTabTypes.PriceAsc:
        sort = {propName: 'RentalPrice', direction: 'Asc'};
        break;
      case SortTabTypes.PriceDesc:
        sort = {propName: 'RentalPrice', direction: 'Desc'};
        break;
      default:
        break;
    }

    const priceRanges = [];
    filterPriceTypes.forEach(x => {
      const prices = searchParams.get(x.urlKey)?.split('-');
      if (prices && prices.length === 2)
        priceRanges.push({
          priceType: x.type,
          priceFrom: prices[0],
          priceTo: prices[1],
        });
    });

    const attributes = [];
    const attrParams = searchParams.get('attrs')?.split('-') || [];
    if (attrParams.length > 0) {
      attrParams.forEach(x => {
        const item = x.split(':');
        if (item.length === 2) {
          attributes.push({attributeId: item[0], attributeValueIds: item[1].split(',')});
        }
      });
    }

    return {
      prices: priceRanges && priceRanges.length > 0 ? priceRanges : undefined,
      attributes: attributes.length > 0 ? attributes : undefined,
      pagination,
      sort,
      brandIds: searchParams.get('brands')?.split(',') || undefined,
      madeIns: searchParams.get('madeIns')?.split(',') || undefined,
      categoryIds: category && category.id ? [category.id] : undefined,
      collectionId: searchParams.get('id') || undefined,
      search: searchParams.get('keyword') || undefined,
      availableFrom: searchParams.get('availableFrom') || undefined,
      availableTo: searchParams.get('availableTo') || undefined,
      // rate: searchParams.get('rate') || undefined,
    };
  };

  const getProductList = async () => {
    try {
      setProducts([]);
      const requestParams = getRequestParams();
      const data = await post('/v1/consumer/product/list', requestParams);
      if (!data) return;

      setProducts(data.items || []);
      setTotal(data.total);
    } catch {}
  };

  const getCategoryMenu = async () => {
    setCategoryMenu(null);
    const data = await get(`/v1/menu-filter${category?.id ? `?categoryIds=${category.id}` : ''}`);
    if (!data) return;

    setCategoryMenu(data);
  };

  useEffect(() => {
    const getPagingProductList = async () => {
      try {
        setPagingLoading(true);
        const requestParams = getRequestParams();
        const data = await post('/v1/consumer/product/list', requestParams);
        if (!data) return;

        setProducts(data.items || []);
        setTotal(data.total);
      } catch {
      } finally {
        setPagingLoading(false);
      }
    };
    getPagingProductList();
  }, [pagination]);

  useEffect(() => {
    if (!category) return;
    if (categoryState.pathname === location.pathname && categoryState.search === location.search) return;
    const selectedItem = categoryMenu?.categories.find(x => x.id == category.id);
    setSelectedCategory(selectedItem);
  }, [categoryMenu?.categories]);

  useEffect(() => {
    if (categoryState.pathname === location.pathname && categoryState.search === location.search) return;
    setProductLoading(true);
    props.keepPagination(defaultPagination);
    Promise.all([getProductList()]).finally(() => {
      setProductLoading(false);
    });
  }, [searchParams]);

  useEffect(() => {
    if (categoryState.pathname === location.pathname && categoryState.search === location.search) return;

    if (category?.id !== selectedCategory?.id) {
      getCategoryMenu();
    }
  }, [category]);

  useEffect(() => {
    const getAllBanners = async () => {
      await getBanners();
    };

    if (categoryState.pathname === location.pathname && categoryState.search === location.search) {
      getAllBanners();
      setProducts([...categoryState.products]);
      setCategoryMenu({...categoryState.categoryMenu});
      props.keepPagination({...categoryState.pagination});
      setTotal(categoryState.total);
      setLoading(false);
      setProductLoading(false);
      if (categoryState.position) {
        document.getElementsByTagName('body')[0].style.height = `${categoryState.position}px`;
        window.scrollTo(0, categoryState.position);
      }
      resetCurCategoryState();
      return;
    }

    setLoading(true);
    // setPagination(defaultPagination);
    const tasks = [getProductList(), getCategoryMenu()];
    if (!banners || banners.length === 0) tasks.push(getAllBanners());
    Promise.all(tasks).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div id='category'>
      {selectedCategory && selectedCategory.id && <Seo title={selectedCategory.name} />}
      <Row className='cate-banner' justify='center'>
        <Col lg={24} xl={22}>
          {loading ? <Skeleton /> : <BigBanner banners={banners.filter(x => x.type === BannerTypes.Big)} />}
        </Col>
      </Row>
      <Row className='content' justify='center'>
        <Col lg={24} xl={22}>
          <Row>
            <Col xs={24} md={12} lg={5}>
              <CategoryMenu
                menu={categoryMenu}
                categoryId={category?.id}
                searchParams={searchParams}
                currentPath={location.pathname}
              />
            </Col>
            <Col xs={24} md={12} lg={19}>
              {loading || productLoading ? (
                <Skeleton />
              ) : (
                <div>
                  {searchParams.get('keyword') && (
                    <div className='search-result'>
                      Kết quả tìm kiếm cho "<span className='result'>{searchParams.get('keyword')}</span>"
                    </div>
                  )}
                  <Products
                    searchParams={searchParams}
                    products={products}
                    total={total}
                    pagination={pagination}
                    currentPath={location.pathname}
                    onShowMore={handleShowMore}
                    pagingLoading={pagingLoading}
                    updateCategoryState={updateCategoryState}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
Category.prototype = {
  keepPagination: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}),
  category: PropTypes.shape({}).isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  banners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getBanners: PropTypes.func.isRequired,
  categoryState: PropTypes.shape({}).isRequired,
  resetCurCategoryState: PropTypes.func.isRequired,
};
Category.defaultProps = {
  pagination: defaultPagination,
};
export default connect(
  state => ({
    banners: state.banner.banners,
    pagination: state.product.pagination,
    categoryState: state.category,
  }),
  dispatch => ({
    getBanners: () => dispatch(bannerAction.getAllBanners()),
    keepPagination: value => dispatch(keepPaginationState(value)),
    setCurCategoryState: state => dispatch(setCategoryState(state)),
    resetCurCategoryState: () => dispatch(resetCategoryState()),
  })
)(Category);
