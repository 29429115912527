import React, {useEffect, useState} from 'react';
import {Collapse, Input, Skeleton, Tag, Checkbox, Row, Col} from 'antd';
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import {Link, navigate} from 'gatsby';

import {getTextColor, numberToCurrency, parseNameToUrl} from '~utils/helper';
import {filterPriceTypes} from '~constants/product-list';
import Star from '~components/icons/star';
import Expand from '~components/icons/expand';
import CollapseIcon from '~components/icons/collapse';

import './styles.scss';

const {Panel} = Collapse;

const rates = Array.from(Array(5).keys());

const CategoryMenu = ({menu, categoryId, searchParams, currentPath}) => {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [priceRanges, setPriceRanges] = useState({rentalPrice: {}, salePrice: {}});
  const [attributes, setAttributes] = useState({});
  const [rate, setRate] = useState(0);
  const [category, setCategory] = useState([]);
  const [rootCategory, setRootCategory] = useState(null);

  const removeFilter = () => {
    setSelectedBrands([]);
    setPriceRanges({rentalPrice: {}, salePrice: {}});
    setAttributes({});
    setRate(0);
  };

  useEffect(() => {
    if (!menu) return;

    if (categoryId) {
      const selectedCategory = menu.categories.find(x => x.id == categoryId);
      if (!selectedCategory.parentId) {
        const categories = menu.categories.filter(x => x.parentId > 0 && x.path.split('/')[0] == categoryId);
        setCategory(categories);
        setRootCategory(selectedCategory);
      } else {
        const parentCategory = menu.categories.find(x => x.id == selectedCategory.parentId);
        const categories = menu.categories.filter(x => x.parentId > 0 && x.path.split('/')[0] == parentCategory.id);
        setCategory(categories);
        setRootCategory(parentCategory);
      }
    } else {
      const categories = menu.categories.filter(x => !x.parentId || x.parentId === 0);
      setCategory(categories);
      setRootCategory(null);
    }

    const newSize = [];
    menu.attributes.forEach(att => {
      if (att.code === 'Size') {
        att.values.forEach(value => {
          const removeItem = ['0-0', '000', '64', '67', '85', '90'];
          if (!removeItem.includes(value.value)) {
            newSize.push(value);
          }
        });
        att.values = newSize;
      }
    });
  }, [menu]);

  useEffect(() => {
    removeFilter();
  }, [categoryId]);

  useEffect(() => {
    if (!searchParams.toString()) {
      removeFilter();
      return;
    }
    const brands = searchParams.get('brands')?.split(',') || [];
    setSelectedBrands(brands);

    const priceList = {rentalPrice: {}, salePrice: {}};
    filterPriceTypes.forEach(x => {
      const prices = searchParams.get(x.urlKey)?.split('-') || [];
      if (prices && prices.length === 2) priceList[x.key] = {minPrice: prices[0], maxPrice: prices[1]};
    });
    setPriceRanges({...priceList});

    setRate(searchParams.get('rate'));

    const attributeList = {};
    const attrParams = searchParams.get('attrs')?.split('-') || [];
    if (attrParams.length > 0) {
      attrParams.forEach(x => {
        const item = x.split(':');
        if (item.length === 2) {
          attributeList[item[0]] = item[1].split(',');
        }
      });
    }

    setAttributes(attributeList);
  }, [searchParams]);

  const isPriceRangeValid = (minPrice, maxPrice) => parseInt(minPrice) <= parseInt(maxPrice);

  const handleApplyFilter = () => {
    // 1. brand - brands={brandId},{brandId}
    selectedBrands.length > 0 ? searchParams.set('brands', selectedBrands.join(',')) : searchParams.delete('brands');

    // 2. Attributes - attrs={attrIds}:{valueIds},{valueIds}-{attrIds}:{valueIds},{valueIds}
    if (Object.keys(attributes).length > 0) {
      searchParams.set(
        'attrs',
        Object.keys(attributes)
          .map(x => `${x}:${attributes[x].join(',')}`)
          .join('-')
      );

      if (!searchParams.get('attrs')) searchParams.delete('attrs');
    }

    // 3. Prices - sale-prices={minPrice}-{maxPrice}&rental-prices={minPrice}-{maxPrice}
    filterPriceTypes.forEach(({key, urlKey}) => {
      if (
        priceRanges[key].minPrice &&
        priceRanges[key].maxPrice &&
        isPriceRangeValid(priceRanges[key].minPrice, priceRanges[key].maxPrice)
      ) {
        searchParams.set(urlKey, `${priceRanges[key].minPrice}-${priceRanges[key].maxPrice}`);
      } else {
        searchParams.delete(urlKey);
      }
    });

    // 4. Rate - rate={value}
    rate > 0 ? searchParams.set('rate', rate) : searchParams.delete('rate');

    navigate(`${currentPath}?${searchParams.toString()}`);
  };

  const handleRemoveFilter = () => {
    removeFilter();
    navigate('/all-categories');
  };

  const handleCheckBrand = id => {
    selectedBrands.find(x => x == id)
      ? selectedBrands.splice(
          selectedBrands.findIndex(x => x == id),
          1
        )
      : selectedBrands.push(id);

    setSelectedBrands([...selectedBrands]);
  };

  const onInputChange = (key, {target: {name, value}}) => {
    const newValue = value.replace(/\./g, '');
    const reg = /^\d*([\d]*)?$/;
    if (!newValue || reg.test(newValue)) {
      setPriceRanges({...priceRanges, [key]: {...priceRanges[key], [name]: newValue}});
    }
  };

  const handleCheckRate = value => {
    setRate(value);
  };

  const handleSelectAttribute = (attrId, attrValueId) => {
    if (!attributes[attrId]) attributes[attrId] = [];
    const existedValueIdx = attributes[attrId].findIndex(x => x == attrValueId);
    existedValueIdx > -1 ? attributes[attrId].splice(existedValueIdx, 1) : attributes[attrId].push(attrValueId);

    if (attributes[attrId].length === 0) delete attributes[attrId];
    setAttributes({...attributes});
  };

  if (!menu) return <Skeleton />;

  return (
    <div className='category-menu'>
      <div className='menu-title'>
        Bộ Lọc Sản Phẩm
        <div className='btn-filter'>
          <div className='apply-filter' onClick={handleApplyFilter}>
            Áp dụng
          </div>
          <div className='remove-filter' onClick={handleRemoveFilter}>
            Bỏ chọn
          </div>
        </div>
      </div>
      <div>
        <Collapse
          expandIcon={({isActive}) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
          expandIconPosition='right'
          ghost
        >
          <Panel key='category' header='DANH MỤC SẢN PHẨM'>
            <ul className='category-list'>
              <li className='category-item'>
                <Link to='/all-categories'>Tất Cả</Link>
              </li>
              {!rootCategory ? (
                category.map(x => (
                  <li key={x.id} className={`category-item ${categoryId == x.id ? 'selected-category' : ''}`}>
                    <Link to={`/${parseNameToUrl(x.name)}.cat.${x.id}`}>{x.name}</Link>
                    <span style={{marginLeft: '12px'}}>
                      <Expand />
                    </span>
                  </li>
                ))
              ) : (
                <li className='parent-category'>
                  <div className={categoryId == rootCategory.id ? 'selected-category' : ''}>
                    <Link to={`/${parseNameToUrl(rootCategory.name)}.cat.${rootCategory.id}`}>{rootCategory.name}</Link>
                    <span style={{marginLeft: '12px'}}>
                      <CollapseIcon />
                    </span>
                  </div>
                  <ul className='child-category'>
                    {category.map(x => (
                      <li key={x.id} className={`child-category-item ${categoryId == x.id ? 'selected-category' : ''}`}>
                        <Link to={`/${parseNameToUrl(x.name)}.cat.${x.id}`}>{x.name}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              )}
            </ul>
          </Panel>
          <Panel key='brand' header='THƯƠNG HIỆU'>
            <ul className='collapse-list'>
              {menu.brands.map(x => (
                <li key={x.id} className='collapse-item'>
                  <Checkbox
                    indeterminate={selectedBrands.findIndex(y => x.id == y) > -1}
                    onChange={e => handleCheckBrand(x.id)}
                    checked={false}
                  >
                    {x.name}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </Panel>
          {menu.attributes.map(
            item =>
              item.values && (
                <Panel key={item.id} header={<span style={{textTransform: 'uppercase'}}>{item.name}</span>}>
                  <div className='collapse-list'>
                    {item.valueType === 'VALUE_TYPE_COLOR' ? (
                      item.values.map(y => (
                        <Tag
                          key={y.id}
                          onClick={() => handleSelectAttribute(item.id, y.id)}
                          className='attribute-item'
                          style={{
                            backgroundColor: y.value,
                            color: getTextColor(y.value),
                            width: '26px',
                            height: '26px',
                            borderRadius: '26px',
                          }}
                        >
                          <div
                            className={`selected-item hover-item ${
                              attributes[item.id]?.findIndex(item => item == y.id) > -1 ? 'display' : ''
                            }`}
                          />
                        </Tag>
                      ))
                    ) : (
                      <ul className='collapse-list'>
                        {item.values.map(y => (
                          <li key={y.id} className='collapse-item'>
                            <Checkbox
                              indeterminate={
                                attributes[item.id] && attributes[item.id].findIndex(id => id == y.id) > -1
                              }
                              checked={false}
                              onChange={() => handleSelectAttribute(item.id, y.id)}
                            >
                              {y.value}
                            </Checkbox>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </Panel>
              )
          )}
          <Panel key='price-ranges' header='KHOẢNG GIÁ'>
            <div className='collapse-list'>
              {filterPriceTypes.map(item => (
                <Row key={item.key}>
                  <label className='price-label' htmlFor='min-price'>
                    {item.title}
                  </label>
                  <Row className='price-input'>
                    <Col span={11}>
                      <Input
                        id='min-price'
                        value={numberToCurrency(priceRanges[item.key].minPrice)}
                        name='minPrice'
                        maxLength={11}
                        placeholder='Nhỏ nhất'
                        onChange={e => onInputChange(item.key, e)}
                      />
                    </Col>
                    <Col span={1} />
                    <Col span={11}>
                      <Input
                        id='max-price'
                        value={numberToCurrency(priceRanges[item.key].maxPrice)}
                        name='maxPrice'
                        maxLength={11}
                        placeholder='Lớn nhất'
                        onChange={e => onInputChange(item.key, e)}
                      />
                    </Col>
                  </Row>
                </Row>
              ))}
            </div>
          </Panel>
          <Panel key='rate' header='ĐÁNH GIÁ'>
            {Array.from(rates)
              .reverse()
              .map(count => (
                <div
                  key={count}
                  className={`rate ${count + 1 == rate ? 'selected-rate' : ''}`}
                  onClick={() => handleCheckRate(count + 1)}
                >
                  {rates.map(x => (
                    <span key={x} className='rate-item'>
                      <Star fill={x <= count ? '#FCBAA4' : 'white'} />
                    </span>
                  ))}
                </div>
              ))}
          </Panel>
        </Collapse>
        <div className='btn-filter'>
          <div className='apply-filter' onClick={handleApplyFilter}>
            Áp dụng
          </div>
          <div className='remove-filter' onClick={handleRemoveFilter}>
            Bỏ chọn
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryMenu;
