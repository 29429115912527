import React, {useEffect, useState} from 'react';
import CalendarV2 from '~components/icons/calendar-v2';
import {formatDate} from '~utils/helper';
import ChevronLeft from '~components/icons/chevron-left';
import ChevronRight from '~components/icons/chevron-right';
import './styles.scss';

const WeeksVN = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];
const Months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

const DateRangePicker = ({dateRange = [], disableDates = [], onChange}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendar, setCalendar] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [datesPicker, setDatesPicker] = useState([]);

  useEffect(() => {
    setDatesPicker(dateRange);
  }, dateRange);

  useEffect(() => {
    const daysInMonth = (y, m) => new Date(y, m + 1, 0).getDate();
    const numberToArrayDates = (n, y, m) => Array.from({length: n}, (_, i) => i + 1).map(x => new Date(y, m, x));

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    let prevCalendar = numberToArrayDates(daysInMonth(year, month - 1), year, month - 1);
    const nowCalendar = numberToArrayDates(daysInMonth(year, month), year, month);
    let nextCalendar = numberToArrayDates(daysInMonth(year, month + 1), year, month + 1);

    const firstDay = new Date(year, month, 1).getDay() - 1;
    prevCalendar = prevCalendar.slice(prevCalendar.length - firstDay, prevCalendar.length);
    nextCalendar = nextCalendar.slice(0, 42 - [...prevCalendar, ...nowCalendar].length);
    setCalendar([...prevCalendar, ...nowCalendar, ...nextCalendar]);
  }, [currentDate]);

  const isDisableDate = _date => {
    _date.setHours(0, 0, 0, 0);
    const isPastDate = _date < new Date().setHours(0, 0, 0, 0);

    if (
      disableDates.length > 0 &&
      !disableDates.every(x => !(_date >= new Date(x.dateFrom) && _date <= new Date(x.dateTo)))
    ) {
      return true;
    }

    if (datesPicker.length === 1) {
      const startDate = new Date(datesPicker[0].toDateString());
      const endDate = new Date(datesPicker[0].toDateString());
      const dateOf2Week = 14; // max date range for rental
      startDate.setDate(startDate.getDate() + dateOf2Week);
      startDate.setHours(0, 0, 0, 0);
      endDate.setDate(endDate.getDate() - dateOf2Week);
      endDate.setHours(0, 0, 0, 0);
      return isPastDate || _date >= startDate || _date <= endDate;
    }

    return isPastDate;
  };

  const isToday = _date => _date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

  const isNotCurrentMonth = _date => _date.getMonth() !== currentDate.getMonth();

  const isDateRange = _date => {
    if (datesPicker.length === 2) {
      return datesPicker[0] <= _date && datesPicker[1] >= _date;
    }
    return datesPicker[0] && datesPicker[0].getTime() === _date.getTime();
  };

  const isIncludeDisabledDates = (startDate, endDate) => {
    return !disableDates.every(x => !(startDate <= new Date(x.dateFrom) && endDate >= new Date(x.dateTo)));
  };

  const onPickDate = _date => {
    switch (datesPicker.length) {
      case 0:
      case 2:
        setDatesPicker([_date]);
        break;
      case 1: {
        const clone = [...datesPicker];
        if (
          (clone[0] < _date && isIncludeDisabledDates(clone[0], _date)) ||
          (clone[0] > _date && isIncludeDisabledDates(_date, clone[0]))
        )
          clone[0] = _date;
        else if (clone[0] > _date) {
          clone.unshift(_date);
        } else {
          clone.push(_date);
        }
        setDatesPicker(clone);
        break;
      }
      default:
        break;
    }
  };

  const onMonthChange = type => {
    const date = new Date(currentDate);
    if (type === 'prev') {
      date.setMonth(date.getMonth() - 1);
      setCurrentDate(date);
    }
    if (type === 'next') {
      date.setMonth(date.getMonth() + 1);
      setCurrentDate(date);
    }
  };

  const onApply = () => {
    setShowContent(!showContent);
    onChange(datesPicker);
  };

  const onRemove = () => {
    setShowContent(!showContent);
    onChange([]);
  };

  return (
    <div className='date-range-picker-custome'>
      <div className='input-date' onClick={() => setShowContent(!showContent)}>
        <span>
          <CalendarV2 />
        </span>
        <span>{datesPicker[0] ? formatDate(datesPicker[0], 'DD-MM-YYYY') : 'Từ ngày'}</span>
        <span> - </span>
        <span>{datesPicker[1] ? formatDate(datesPicker[1], 'DD-MM-YYYY') : 'Đến ngày'}</span>
      </div>
      {showContent && (
        <div className='rental-date-picker'>
          <div className='header'>
            <div>
              Tháng {Months[currentDate.getMonth()]}, {currentDate.getFullYear()}
            </div>
            <div className='btn-change-month'>
              <button aria-label='Prev' onClick={() => onMonthChange('prev')}>
                <ChevronLeft fill='#000000' size='16' />
              </button>
              <button aria-label='Next' onClick={() => onMonthChange('next')}>
                <ChevronRight fill='#000000' size='16' />
              </button>
            </div>
          </div>
          <div className='weeks'>
            {WeeksVN.map(item => (
              <div key={item}>{item}</div>
            ))}
          </div>
          <div className='dates'>
            {calendar.map(item => (
              <button
                className={`${isDisableDate(item) && 'disable'} ${isToday(item) && 'today'} ${
                  isNotCurrentMonth(item) && 'not-current-month'
                } ${isDateRange(item) && 'date-range'}`}
                disabled={isDisableDate(item)}
                onClick={() => onPickDate(item)}
              >
                {item.getDate()}
              </button>
            ))}
          </div>
          <div className='btn-footer'>
            <button className='remove-btn' disabled={datesPicker.length === 0} onClick={() => onRemove()}>
              Bỏ chọn
            </button>
            <button onClick={() => onApply()} disabled={datesPicker.length === 0}>
              Áp dụng
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
