import React, {useState} from 'react';
import {Col, Row} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {navigate} from 'gatsby';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {LazyLoadImage} from 'react-lazy-load-image-component';
import {buildImageUrl} from '~utils/helper';
import logoBackground from '~images/logo-background.svg';
import './styles.scss';

const BigBanner = ({banners, location}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const onClickItem = url => {
    if (url.indexOf('//') === 0 || url.indexOf('http') === 0) {
      if (url.includes(location?.hostname)) {
        navigate(url);
        return;
      }
      window.open(url, '_blank');
      return;
    }

    const newUrl = url.includes('/') ? url : `/${url}`;

    navigate(newUrl);
  };

  const next = () => (selectedItem === banners.length ? setSelectedItem(0) : setSelectedItem(selectedItem + 1));

  const prev = () => (selectedItem === 0 ? setSelectedItem(banners.length) : setSelectedItem(selectedItem - 1));

  return (
    <Row justify='space-between' gutter={[20, 20]} className='banner-vertical'>
      <Col span={18} className='padding'>
        <div className='big-banner'>
          <div className='prev' onClick={prev}>
            <span className='prev-icon'>
              <LeftOutlined />
            </span>
          </div>
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showArrows={false}
            selectedItem={selectedItem}
            showStatus={false}
          >
            {banners.map(x => (
              <div className='banner-item' key={`banner-${x.id}`} title={x.caption} onClick={() => onClickItem(x.url)}>
                <LazyLoadImage
                  className='banner-image'
                  src={buildImageUrl(x.imageUrl, '948x360')}
                  alt={x.name}
                  width={948}
                  height={360}
                />
                <div className='banner-item-caption'>{x.caption}</div>
              </div>
            ))}
          </Carousel>
          <div className='next' onClick={next}>
            <span className='next-icon'>
              <RightOutlined />
            </span>
          </div>
        </div>
      </Col>
      <Col span={6} className='padding'>
        <div className='b-rentzy-logo'>
          <img src={logoBackground} alt='Rentzy background' />
        </div>
      </Col>
    </Row>
  );
};
export default BigBanner;
