import React from 'react';

const Expand = ({fill = '#828282', size = '7'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 8 6'
    width={size}
    height={size}
    fill={fill}
  >
    <path d='M3.72481 5.5L0.116372 0.5L7.33325 0.5L3.72481 5.5Z' />
  </svg>
);

export default Expand;
