import React, {useEffect} from 'react';
import Layout from '~components/layout';
import Seo from '~components/seo';
import CategoryContainer from '~containers/category';
import {parseRouter} from '~utils/helper';
import {RouterKeys} from '~utils/enum';
import {firebaseAnalytics} from '~utils/firebase';

const Category = props => {
  useEffect(() => {
    firebaseAnalytics.logEvent('Product_list_visited');
  }, []);

  const {
    params: {categoryLink},
  } = props;
  const category = parseRouter(categoryLink, RouterKeys.Category);

  const {search} = props.location;
  const searchParams = new URLSearchParams(search.slice(1));

  return (
    <Layout>
      <Seo title={category.name} />
      <CategoryContainer {...props} category={category} searchParams={searchParams} />
    </Layout>
  );
};

export default Category;
